/*Animation
----------------------------------------------------------*/
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes fadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.animated.fadeInDown {
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
.animated {
  -webkit-animation-duration: 0.7s;
  -moz-animation-duration: 0.7s;
  -o-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}
.cta {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.cta2 {
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
  -o-animation-delay: 1.2s;
  animation-delay: 1.2s;
}
.cta3 {
  -webkit-animation-delay: 1.8s;
  -moz-animation-delay: 1.8s;
  -o-animation-delay: 1.8s;
  animation-delay: 1.8s;
}
.Site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.Site-content {
  flex: 1;
}
/* Team 
----------------------------------------------------------*/
.team {
  margin-top: 80px;
  text-align: center;
}
.team .team-player .title {
  margin: 30px auto;
}
.team .team-player img {
  max-width: 170px;
}
/* Header
----------------------------------------------------------*/
.logo {
  background: #EAEAEA;
}
.modal-content {
  background-color: #000;
}
#liVersionSwitcher a {
  height: 42px;
}
#liVersionSwitcher img {
  width: 90px;
}
/*Share
----------------------------------------------------------*/
.share-logo:hover {
  color: #656666;
}
.share-facebook:hover {
  color: #3B579D;
}
.share-youtube:hover {
  color: #E22C26;
}
.share-gplus:hover {
  color: #dd4b39;
}
.share-logo {
  color: #000;
  font-size: 40px;
}
.share {
  background: #E6E6E6;
  color: #ddd;
  display: none;
  border-bottom: 1px solid;
  left: 0;
  right: 0;
}
.sharebtn {
  margin: 5px;
}
/* page titles 
----------------------------------------------------------*/
hgroup.title {
  margin-bottom: 10px;
}
hgroup.title hgroup.title h1,
hgroup.title hgroup.title h2 {
  display: inline;
}
hgroup.title hgroup.title h2 {
  font-weight: normal;
  margin-left: 3px;
}
/* main layout
----------------------------------------------------------*/
.fb-comments,
.fb-comments span,
.fb-comments iframe {
  width: 100% !important;
}
.fb_iframe_widget {
  display: inline-block;
  position: relative;
}
body {
  background-color: #000;
  color: #FFF;
}
.Social {
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
}
/*About
----------------------------------------------------------*/
.about img {
  margin: 0 20px;
  max-width: 90px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  position: relative;
  top: 0;
  -moz-box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.4);
  -webkit-transition: top 0.15s linear;
  -moz-transition: top 0.15s linear;
  -o-transition: top 0.15s linear;
  transition: top 0.15s linear;
}
.about {
  margin-top: 75px;
  -webkit-font-smoothing: auto;
}
.about .about-row {
  text-align: center;
  margin-bottom: 35px;
}
/*About Animation*/
.about-hover:hover {
  top: -5px;
  -moz-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 600px) {
  .about {
    display: none;
  }
}
.navbar .navbar-nav > li > a {
  color: #FFF;
}
.navbar-brand {
  color: #FFF;
}
.navbar .navbar-nav > .active > a,
.navbar .navbar-nav > .active > a:hover,
.navbar .navbar-nav > .active > a:focus {
  color: lightgrey;
}
.navbar .navbar-nav > li > a:hover,
.navbar .navbar-nav > li > a:focus {
  color: lightgrey;
}
.row-head {
  margin-top: 80px;
  margin-bottom: 50px;
}
/*********************
  Mixins
**********************/
/*********************
  VARIABLES
**********************/
.z-depth-1,
.btn,
.btn-floating,
.card-panel,
.card,
.dropdown-content,
.dropdown-menu {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0.12);
}
.top-nav-collapse {
  background-color: #000 !important;
}
/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .navbar {
    background-color: #000 !important;
  }
}
/*********************
  MAIN CAROUSEL
**********************/
.carousel,
.item,
.active {
  height: 100%;
}
.carousel-inner {
  height: 100%;
}
.i-one {
  background-image: url("../images/heroes/hero1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.i-two {
  background-image: url("../images/heroes/hero2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.i-four {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
/*********************
  TEMPLATE COLORS
**********************/
.pt-color {
  background-color: #1C2331 !important;
}
.st-color {
  background-color: #4D5668 !important;
}
.et-color {
  background-color: #3F729B !important;
}
.btn-ptc {
  background-color: #1C2331 !important;
}
.btn-ptc:hover {
  background-color: #2f3a51 !important;
}
.btn-ptc:focus {
  background-color: #2f3a51 !important;
}
.btn-stc {
  background-color: #4D5668 !important;
}
.btn-stc:hover {
  background-color: #636e85 !important;
}
.btn-stc:focus {
  background-color: #636e85 !important;
}
.btn-etc {
  background-color: #3F729B !important;
}
.btn-etc:hover {
  background-color: #548cb9 !important;
}
.btn-etc:focus {
  background-color: #548cb9 !important;
}
.btn-youtube {
  background-color: #E62117 !important;
}
.btn-youtube:hover {
  background-color: #E62117 !important;
}
.btn-youtube:focus {
  background-color: #E62117 !important;
}
.fb-bg {
  background-color: #3B5998;
}
.youtube-bg {
  background-color: #E62117;
}
.gplus-bg {
  background-color: #DD4B39;
}
/*********************
  FOOTER
**********************/
footer.page-footer {
  margin-top: 0;
  background-color: #000;
}

/*# sourceMappingURL=maps/main.css.map */
